import React, { useState } from 'react';
import {
  LABEL,
  TEXT,
  URL_ENQUIRIES_NEW_CONTACTS,
} from '@moxie/constants';
import { Button, Row, Tooltip } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { getEnquiryForms } from '@crm/services.api';
import { EnquiryForm } from '@model/contact-form';
import { CrmTable } from '@shared-components/elements';
import Typography from 'antd/es/typography';
import ShareActionBtn from './form-listing-action/share-action-btn';
import ShareUrlModal from './form-listing-action/share-url-modal';

const EnquiryFormsTable = () => {
  const [showShareModal, setShowShareModal] = useState(false);
  const columns: ColumnsType<EnquiryForm> = [
    {
      title: LABEL.FORM_NAME,
      dataIndex: 'formName',
      key: 'formName',
      width: 250,
      render: (text: string, rowData: EnquiryForm) => {
        return (
          <Row align="middle">
            <Typography.Paragraph
              className="initial_capital table-form-name-width"
              ellipsis={{
                rows: 1,
                tooltip: <Tooltip title={rowData.formName}>{rowData.formName}</Tooltip>,
              }}
            >
              {' '}
              {rowData.formName}
            </Typography.Paragraph>
          </Row>
        );
      },
    },
    {
      title: LABEL.SHARING_OPTION,
      dataIndex: 'id',
      key: 'id',
      width: 300,
      render(id: string) {
        return (
          <ShareActionBtn key={id} setShowShareModal={setShowShareModal} />
        );
      },
    },
    {
      title: LABEL.UPDATED_AT,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      render: (updated_at: Date) => {
        return (
          <>
            <Typography.Text strong>
              {moment(updated_at).format('Do MMM, YYYY')}
            </Typography.Text>
            <div className="justify-content-center">
              <Typography.Text strong className="text-primary-color">
                {moment(updated_at).format('dddd')}
              </Typography.Text>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="bg-white padding-left-1 padding-right-1">
        <CrmTable
          action={getEnquiryForms}
          columns={columns}
          tableName=""
          searchBarClassName="padding-top-1"
          tableButtons={
            <Row className="padding-1">
              <Link to={URL_ENQUIRIES_NEW_CONTACTS}>
                <Button type="ghost" icon={<LeftOutlined />}>
                  {TEXT.ENQUIRIES}
                </Button>
              </Link>
            </Row>
          }
        />
      </div>
      <ShareUrlModal showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
    </>
  );
};
export { EnquiryFormsTable };
