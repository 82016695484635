/* eslint-disable no-unused-vars */
import { CANCEL_BTN, SAVE_BTN } from '@moxie/constants';
import { DrawerElem as Drawer } from '@shared-components/elements';
import { Button, FormInstance, Space } from 'antd';
import Tabs from 'antd/lib/tabs';
import Typography from 'antd/lib/typography';
import React from 'react';
import ActivityLog from './ActivityLog';
import Comments from './application-comments';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  form: FormInstance<any>;
  handleCommentFormSubmit: React.Dispatch<any>;
  isEdit: boolean;
  isSubmitted: boolean;
  handleSubmit: (body: any) => void;
  initialData: any;
  disabled: boolean;
  setDisabled: React.Dispatch<any>;
  applicationId: string;
  applicationData: any;
  selectedStage: any;
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

export enum TabLabel {
  activities = 'activities',
  comments = 'comments',
}

const ApplicationActivityLogDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  form,
  handleCommentFormSubmit,
  handleSubmit,
  initialData,
  disabled,
  setDisabled,
  applicationId,
  selectedStage,
  selectedTab,
  setSelectedTab,
  applicationData,
}) => {
  const tabPanels = React.useMemo(() => {
    return [
      {
        label: TabLabel.activities,
        panel: <ActivityLog />,
      },
      {
        label: TabLabel.comments,
        panel: (
          <Comments
            data-testid="activitytab"
            form={form}
            onSubmit={handleSubmit}
            initialData={initialData}
            setDisabled={setDisabled}
            setSelectedTab={setSelectedTab}
            applicationId={applicationId}
            selectedStage={selectedStage}
            applicationData={applicationData}
          />
        ),
      },
    ];
  }, [selectedStage]);

  return (
    <Drawer
      data-testid="activitydrawer"
      title="Activity log"
      placement="right"
      onClose={onClose}
      visible={isOpen}
      width={600}
      footer={
        selectedTab === 'comments' && (
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={onClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              <Button
                type="primary"
                onClick={handleCommentFormSubmit}
                disabled={disabled}
              >
                {SAVE_BTN}
              </Button>
            </Space>
          </div>
        )
      }
    >
      <Tabs
        data-testid="activitytab"
        defaultActiveKey={selectedTab}
        onChange={(tabName: string) => {
          setSelectedTab(tabName);
        }}
      >
        {tabPanels.map((tab) => (
          <Tabs.TabPane
            className={'custom_tab'}
            key={tab.label}
            tab={
              <Typography.Text strong className="initial_capital">
                {tab.label}
              </Typography.Text>
            }
          >
            {tab.panel}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Drawer>
  );
};

export default ApplicationActivityLogDrawer;
