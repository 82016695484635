import React, { useContext, useState } from 'react';
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  CloseOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { successNotificationHandler } from '@moxie/shared';
import { useAppSelector } from '@crm/core';
import { ActivityAction } from '@model/contact-activity';
import ApplicationDetailContext from '../../../application-detail-context';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { ApplicationStatusChangeRemarks } from '@model/application';
import StatusUpdateModal from './status-update-modal';
import { discontinueReasonsOptions, revertReasonsOptions } from '../../../constants';

type Modal = 'revert' | 'discontinue';

const Footer = () => {
  const { applicationData: application, setSelectedStage, service } = useContext(ApplicationDetailContext);

  const applicationId = application?.id;
  const activeStage = application.activeStage;
  const allWfStages = application?.workflow.workflowApplicationStages;
  const isSecondLastStage = !application?.nextStageId;
  const hasPreviousStage = !!application?.prevStageId;
  const isDiscontinued = application?.status === 'Discontinued';
  const isCompleted = application?.status === 'Completed';
  const updateApplicationMutation = useUpdateApplicationMutation(applicationId, application.dealId);

  const [activeModal, setActiveModal] = useState<Modal | undefined>();
  const [isSubmitting, setSubmitting] = useState(false);
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const getStageInfo = (stageId: string | null) => {
    return allWfStages?.find((stage) => stage?.id === stageId);
  };

  const getLastWorkflowStage = () => {
    return allWfStages.pop();
  };

  const addChangeLog = (
    previousStage: any,
    nextStage: any,
    action: ActivityAction
  ) => {
    socket?.emit('server::application-updated', {
      activitiesTypeId: application.id,
      activitiesType: 'application',
      activitiesAction: action,
      userId: user?.id as string,
      companyId: user?.companyId as string,
      contactId: application.contactId,
      data: {
        data: {
          ...application,
          currentStage: nextStage,
          previousStage: previousStage,
        },
      },
    });
  };
  const handleNextStage = () => {
    const stageInfo = getStageInfo(application?.nextStageId);

    if (isSecondLastStage) {
      updateApplicationMutation.mutate(
        {
          status: 'Completed',
          service: service ?? ''
        },
        {
          onSuccess() {
            successNotificationHandler('Application stage updated as completed', 1.5);
            addChangeLog(activeStage, stageInfo, 'completed');
            setSelectedStage({
              level: null,
              stage: null,
              wfAppStageId: null
            });
          },
          onError() {
            const currentStage = getStageInfo(application?.activeStageId);
            setSelectedStage({
              level: currentStage?.level ?? null,
              stage: currentStage?.stage ?? null,
              wfAppStageId: currentStage?.id ?? null
            });
          }
        },
      );
    } else {
      updateApplicationMutation.mutate(
        {
          activeStageId: application?.nextStageId as string
        },
        {
          onSuccess() {
            addChangeLog(activeStage, stageInfo, 'next-stage');
            setSelectedStage({
              level: stageInfo?.level ?? null,
              stage: stageInfo?.stage ?? null,
              wfAppStageId: stageInfo?.id ?? null
            });
          },
        }
      );


    }
  };
  const handlePreviousStage = () => {
    const previousStage = getStageInfo(application?.prevStageId);

    if (isCompleted) {
      const stageInfo = getLastWorkflowStage();
      updateApplicationMutation.mutate({ status: 'In Progress' },
        {
          onSuccess() {
            successNotificationHandler('Application stage updated.', 1.5);
            addChangeLog({ stage: 'complete' }, stageInfo, 'previous-stage');
          },
        }
      );
      setSelectedStage({
        level: stageInfo?.level ?? null,
        stage: stageInfo?.stage ?? null,
        wfAppStageId: stageInfo?.id ?? null,
      });
    } else {
      updateApplicationMutation.mutate({ activeStageId: application?.prevStageId as string },
        {
          onSuccess() {
            successNotificationHandler('Application stage updated.', 1.5);
            addChangeLog(activeStage, previousStage, 'previous-stage');
          },
        }
      );
      setSelectedStage({
        level: previousStage?.level ?? null,
        stage: previousStage?.stage ?? null,
        wfAppStageId: previousStage?.id ?? null
      });
    }
  };

  const logApplicationState = (action: ActivityAction) => {
    socket?.emit('server::application-updated', {
      activitiesTypeId: application.id,
      activitiesType: 'application',
      activitiesAction: action,
      userId: user?.id as string,
      companyId: user?.companyId as string,
      contactId: application.contactId,
      data: { data: application },
    });
  };

  const handleDiscontinue = (value: ApplicationStatusChangeRemarks) => {
    setSubmitting(true);
    updateApplicationMutation.mutate(
      {
        status: 'Discontinued',
        statusRemarks: {
          ...application.statusRemarks,
          discontinue: value
        }
      },
      {
        onSuccess() {
          logApplicationState('discontinued');
          successNotificationHandler('Application updated.', 1.5);
        },
        onSettled() {
          setActiveModal(undefined);
          setSubmitting(false)
        }
      }
    )
  };

  const handleRevert = (value: ApplicationStatusChangeRemarks) => {
    setSubmitting(true)
    updateApplicationMutation.mutate(
      {
        status: 'In Progress',
        statusRemarks: {
          ...application.statusRemarks,
          revert: value
        }
      },
      {
        onSuccess() {
          successNotificationHandler('Application reverted.', 1.5);
          logApplicationState('revert');
        },
        onSettled() {
          setActiveModal(undefined);
          setSubmitting(false)
        }
      }
    );
  };

  return (
    <div
      style={{
        borderTop: '1px solid #E3E2E2',
        padding: '0.5rem 1rem',
      }}
    >
      <StatusUpdateModal
        isSubmitting={isSubmitting}
        title="Discontinue Application"
        onSubmit={handleDiscontinue}
        onClose={() => setActiveModal(undefined)}
        options={discontinueReasonsOptions}
        isOpen={activeModal === 'discontinue'}
        formName="discontinue-application-form"
        confirmButton={
          <Button htmlType="submit" icon={<CloseOutlined />} disabled={isSubmitting} danger form="discontinue-application-form">
            Discontinue
          </Button>
        }
      />

      <StatusUpdateModal
        isSubmitting={isSubmitting}
        title="Revert Application"
        onSubmit={handleRevert}
        onClose={() => setActiveModal(undefined)}
        options={revertReasonsOptions}
        isOpen={activeModal === 'revert'}
        formName="revert-application-form"
        confirmButton={
          <Button htmlType="submit" type="primary" icon={<UndoOutlined />} disabled={isSubmitting} form="revert-application-form">
            Revert
          </Button>
        }
      />
      <Row align="bottom" justify="space-between">
        <Col>
          <Row>
            <Button
              icon={<LeftCircleOutlined />}
              style={{
                width: '8rem',
                display: isDiscontinued ? 'none' : '',
              }}
              disabled={!hasPreviousStage || updateApplicationMutation.isLoading}
              onClick={() => handlePreviousStage()}
              data-testid="previousbtn"
            >
              Previous
            </Button>
            {isDiscontinued ? (
              <Button
                icon={<UndoOutlined />}
                style={{ width: '8rem' }}
                type="primary"
                onClick={() => setActiveModal('revert')}
                data-testid="revertbtn"
              >
                Revert
              </Button>
            ) : (
              <Button
                onClick={() => setActiveModal('discontinue')}
                icon={<CloseOutlined />}
                style={{
                  width: '8rem',
                  marginLeft: '15px',
                  display: isDiscontinued || isCompleted ? 'none' : '',
                }}
                danger
                data-testid="discontinuebtn"
              >
                Discontinue
              </Button>
            )}
          </Row>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{
              width: isSecondLastStage ? '12rem' : '8rem',
              display: isDiscontinued || isCompleted ? 'none' : '',
            }}
            onClick={() => handleNextStage()}
            disabled={updateApplicationMutation.isLoading}
            data-testid="nextbtn"
          >
            {isSecondLastStage ? 'Complete Application' : 'Next'}
            <RightCircleOutlined />
          </Button>
        </Col>
      </Row >
    </div >
  );
};
export default Footer;
