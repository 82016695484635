import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { getWfChecklists } from '@crm/libs/services.api/lib/workflow.api';

export const useFetchChecklistDocs = (stageId: string | null) => {
    const { data, isLoading: isLoadingDocs } = useQuery({
        enabled: !!stageId,
        queryKey: ['stage-documents', stageId],
        queryFn: async () => {
            const { data } = await getWfChecklists(stageId as string);
            return data.data
        },
        onError: () => {
            errorNotificationHandler("Error fetching stage documents.");
        }
    });

    return { stageDocs: data ?? [], isLoadingDocs };
};
