import React from "react";
import { ShareAltOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import { Space } from "antd";

interface ShareActionBtnProps {
  setShowShareModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ShareActionBtn = ({ setShowShareModal }: ShareActionBtnProps) => {
  return (
    <>
      <Button type="primary" onClick={() => setShowShareModal(true)}>
        <Space direction="horizontal">
          <ShareAltOutlined />
          Share
        </Space>
      </Button>
    </>
  )
}

export default ShareActionBtn;
