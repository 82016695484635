import { updateApplication } from "@crm/libs/services.api/lib/application.api"
import { UpdateApplicationPayload } from "@model/application"
import { errorNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios, { AxiosError } from "axios";

const useUpdateApplicationMutation = (applicationId: string, dealId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateApplicationPayload) => {
       return updateApplication(applicationId, payload)
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['application-details', applicationId]
      })
      queryClient.invalidateQueries({
        queryKey: ['deal-applications', dealId],
      });
    },
    onError(err: Error | AxiosError) {
      if (axios.isAxiosError(err)) {
        errorNotificationHandler(err?.response?.data?.detail);
      }
    }
  })
}

export default useUpdateApplicationMutation;
