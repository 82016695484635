import React from 'react';
import { createContext, useState } from "react";
import { ApplicationDetail, SelectedStage } from "@model/application";
import { useApplicationDetails } from "./hooks/useApplicationDetails";
import { useParams } from "react-router-dom";
import { ApplicationWorkflowStageChecklist, WorkflowApplicationStage } from "@model/workflow";
import { useFetchChecklistDocs } from './hooks/useFetchChecklistDocs';

interface Values {
    applicationData: ApplicationDetail;
    isLoadingApplication: boolean;
    isCompletedStage: (val: WorkflowApplicationStage) => boolean;
    isCurrentStage: (val: WorkflowApplicationStage) => boolean;
    isSelectedStage: (val: WorkflowApplicationStage) => boolean;
    setSelectedStage: React.Dispatch<React.SetStateAction<SelectedStage>>
    selectedStage: SelectedStage;
    stageDocs: ApplicationWorkflowStageChecklist[];
    isLoadingDocs: boolean,
    service?: string,

}
const ApplicationDetailContext = createContext<Values>({
    applicationData: {} as ApplicationDetail,
    isLoadingApplication: true,
    isCompletedStage: (val) => true,
    isCurrentStage: (val) => true,
    isSelectedStage: (val) => true,
    setSelectedStage: (val) => {return },
    selectedStage: {
        level: null,
        stage: null,
        wfAppStageId: null
    },
    stageDocs: [],
    isLoadingDocs: false,
    service: "",
})

export const ApplicationDetailProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const {
        applicationId,
    }: { contactId: string; applicationId: string } = useParams();
    const { applicationData, isLoadingApplication } = useApplicationDetails(
        applicationId
    );

    const [selectedStage, setSelectedStage] = useState<SelectedStage>({
        stage: null,
        level: null,
        wfAppStageId: null,
    });
    const isCompletedStage = (stageInfo: WorkflowApplicationStage) => {
        return stageInfo.level < (applicationData?.activeStage?.level ?? 0);
    };

    // the current stage of the application processing stages
    const isCurrentStage = (stageInfo: WorkflowApplicationStage) => {
        return stageInfo.level === applicationData?.activeStage?.level;
    };
    // selected stage in the user interface
    const isSelectedStage = (stageInfo: WorkflowApplicationStage) => {
        return stageInfo.level === selectedStage?.level;
    };

    const { stageDocs, isLoadingDocs } = useFetchChecklistDocs(
        selectedStage?.wfAppStageId
      );

    return (
        <ApplicationDetailContext.Provider value={
            {
                applicationData:applicationData as ApplicationDetail,
                isCompletedStage,
                isLoadingApplication,
                isCurrentStage,
                isSelectedStage,
                setSelectedStage,
                selectedStage,
                stageDocs,
                isLoadingDocs,
                service: applicationData?.workflow.workflowType.service.name,
            }
        }>
            {children}
        </ApplicationDetailContext.Provider>
    )
}


export default ApplicationDetailContext;
