import { useContext, useState } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import { Col, Input, Popover, Row, Space, Typography } from 'antd';
import { regex, TEXT } from '@moxie/constants';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useApplicationFeeMutation } from '../../DetailSection/hooks/use-contact-application-fees';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import {
  capitalizeFirstLetter,
  successNotificationHandler,
} from '@moxie/shared';
import { InputButtonWrapper } from '@crm/src/shared/input-button-wrapper';
import { UpdateApplicationDiscountFormField } from '@model/data.model';

const { Text } = Typography;

export const PaymentsTab = () => {
  const { service, applicationData } = useContext(ApplicationDetailContext);
  const [editField, setEditField] = useState<any>({
    fees: false,
    discount: false,
  });
  const feeMutation = useApplicationFeeMutation(applicationData);
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationData.id,
    applicationData.dealId
  );
  const fees = applicationData?.productFeeAmount;
  const currency = applicationData?.institution?.currency;
  const initialInput = {
    fees: applicationData?.productFeeAmount,
    discount: applicationData?.discount,
    remarks: applicationData.remarks ?? '',
  };
  const [input, setInput] = useState(initialInput);

  return (
    <div className="form-container">
      <InputButtonWrapper
        onSubmit={() => {
          feeMutation.mutate(input.fees, {
            onSuccess() {
              successNotificationHandler('Fees updated successfully.', 1.5);
              //TODO: add fees update activities
            },
          });
        }}
        onCancel={() =>
          setInput((input) => ({
            ...input,
            fees: initialInput.fees,
          }))
        }
      >
        <Space direction="vertical">
          <Text strong>{`Fees (${currency})`}</Text>
          <Input
            placeholder="Fees"
            type="number"
            onChange={(e) =>
              setInput((input) => ({
                ...input,
                fees: Number(e.target.value),
              }))
            }
            value={input.fees}
          />
        </Space>
      </InputButtonWrapper>

      <InputButtonWrapper
        onSubmit={() => {
          updateApplicationMutation.mutate(
            { discount: input.discount, remarks: input.remarks },
            {
              onSuccess() {
                successNotificationHandler(
                  'Discount updated successfully.',
                  1.5
                );
              },
            }
          );
        }}
        onCancel={() =>
          setInput((input) => ({
            ...input,
            discount: initialInput.discount,
            remarks: initialInput.remarks,
          }))
        }
      >
        <Space direction="vertical">
          <Text strong>{`Discount (${currency})`}</Text>
          <Input
            type="number"
            placeholder="Discount"
            onChange={(e) =>
              setInput((input) => ({
                ...input,
                discount: Number(e.target.value),
              }))
            }
            value={input.discount}
          />
          <Input
            placeholder="Discount Remarks"
            onChange={(e) =>
              setInput((input) => ({
                ...input,
                remarks: e.target.value,
              }))
            }
            value={input.remarks}
          />
        </Space>
      </InputButtonWrapper>
    </div>
  );
};
