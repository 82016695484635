import React, { useState } from 'react';
import { Button, Row } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import ApplicationActivityLogDrawer from './application-activity-log-drawer';
import { useForm } from 'antd/lib/form/Form';
import { usePostContactApplicationComments } from '../hooks/use-contact-application-comments';
import { errorHandler } from '@moxie/utils';
import { ApplicationDetail } from '@model/application';

interface Props {
  application: ApplicationDetail;
}

const EnrollmentDetail: React.FC<Props> = ({ application }) => {
  const {id: applicationId } = application;

  const [openTab, setOpenTab] = useState(false);
  const [initialData, setInitialData] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedTab, setSelectedTab] = useState('activities');
  const [commentFormButton, setCommentFormButton] = useState<any>(true);
  const commentsMutation = usePostContactApplicationComments(application);
  const [form] = useForm();

  const showDrawer = () => {
    setOpenTab(true);
    setInitialData('');
  };

  const handleCloseDrawer = () => {
    setOpenTab(false);
    setIsEdit(false);
    form.resetFields();
    setCommentFormButton(true);
    setSelectedTab('activities');
  };

  const handleCommentFormSubmit = () => {
    form.submit();
  };

  const handleSubmit = (data: any) => {
    try {
      if (applicationId) {
        commentsMutation.mutate({
          comment: data.comment,
          workflowApplicationStageId: application?.activeStageId,
          applicationId: application.id,
          applicationStatus: application.status
        });
        setIsSubmitted(true);
      }
    } catch (err) {
      errorHandler(err);
    }
    form.resetFields();
    setCommentFormButton(true);
  };


  return (
    <React.Fragment>
      <Row
        justify="end"
        style={{
          borderBottom: '1px solid #E3E2E2',
          padding: '0.8rem 1rem',
        }}
      >
          <Button
            className="custom_comments_button"
            icon={<CommentOutlined />}
            onClick={showDrawer}
            data-testid="activitybtn"
            style={{ marginTop: 0 }}
          >
            Activity Log
          </Button>
      </Row>
      <ApplicationActivityLogDrawer
        isOpen={openTab}
        form={form}
        onClose={handleCloseDrawer}
        handleCommentFormSubmit={handleCommentFormSubmit}
        isEdit={isEdit}
        isSubmitted={isSubmitted}
        handleSubmit={handleSubmit}
        initialData={initialData}
        disabled={commentFormButton}
        setDisabled={setCommentFormButton}
        applicationId={applicationId}
        applicationData={application}
        selectedStage={application?.activeStageId}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </React.Fragment>
  );
};

export default EnrollmentDetail;
