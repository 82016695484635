import { useAppSelector } from '@crm/core';
import { InputButtonWrapper } from '@crm/src/shared/input-button-wrapper';
import { successNotificationHandler } from '@moxie/shared';
import { DatePicker, Input, Space, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { useContext, useState } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { parseDate } from './application-details-tab';

const { Text } = Typography;

export const EducationForm = () => {
  const { applicationData } = useContext(ApplicationDetailContext);
  const {
    endDate,
    startDate,
    intakeMonth,
    intakeYear,
    id: applicationId,
    workflowMetadata,
  } = applicationData;
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationId,
    applicationData?.dealId
  );
  const socket = useAppSelector((store) => store.socket.wss);
  const user = useAppSelector((store) => store.auth.user);
  const initialInput = {
    partnerClientId: applicationData.partnerClientId ?? '',
  };
  const [input, setInput] = useState(initialInput);

  let intakeDate;
  if (intakeMonth === 'NULL') {
    intakeDate = null;
  } else {
    intakeDate = moment(`${intakeMonth} ${intakeYear}`, 'MMMM YYYY');
  }

  let enrollmentStartDate;
  let enrollmentEndDate;

  if (!startDate) {
    enrollmentStartDate = null;
  } else {
    enrollmentStartDate = parseDate(startDate, 'DD/MM/YYYY');
  }

  if (!endDate) {
    enrollmentEndDate = null;
  } else {
    enrollmentEndDate = parseDate(endDate, 'DD/MM/YYYY');
  }

  const handleIntakeUpdate = (data: Moment | null) => {
    const intakeYear = Number(data?.format('YYYY') ?? 0);
    const intakeMonth = data?.format('MMMM') as string;
    updateApplicationMutation.mutate(
      {
        intakeMonth,
        intakeYear,
      },
      {
        onSuccess() {
          successNotificationHandler('Application updated.', 1.5);
          socket?.emit('server::application-updated', {
            activitiesAction: 'updated',
            activitiesType: 'application-intake',
            data: {
              data: {
                contactId: applicationData.contactId,
                id: applicationData.id,
                appIdentifier: applicationData.appIdentifier,
              },
            },
            activitiesTypeId: applicationId,
            companyId: user?.companyId as string,
            contactId: applicationData.contactId,
            userId: user?.id as string,
          });
        },
      }
    );
  };

  const handleEnrollmentUpdate = (data: Moment[] | any) => {
    const startDate = data[0]?.format('DD/MM/YYYY');
    const endDate = data[1]?.format('DD/MM/YYYY');
    updateApplicationMutation.mutate(
      { startDate, endDate },
      {
        onSuccess() {
          successNotificationHandler('Application updated.', 1.5);
          socket?.emit('server::application-updated', {
            activitiesAction: 'updated',
            activitiesType: 'application-enrollment',
            data: {
              data: {
                contactId: applicationData.contactId,
                id: applicationData.id,
                appIdentifier: applicationData.appIdentifier,
              },
            },
            activitiesTypeId: applicationId,
            companyId: user?.companyId as string,
            contactId: applicationData.contactId,
            userId: user?.id as string,
          });
        },
      }
    );
  };

  const updatePartnerClientId = (partnerClientId: string) =>
    updateApplicationMutation.mutate(
      { partnerClientId },
      {
        onSuccess() {
          successNotificationHandler(
            'Provider student id updated successfully.'
          );
          socket?.emit('server::application-updated', {
            activitiesAction: 'updated',
            activitiesType: 'application-partner-client-id',
            data: {
              data: {
                contactId: applicationData.contactId,
                id: applicationData.id,
                appIdentifier: applicationData.appIdentifier,
              },
            },
            activitiesTypeId: applicationId,
            companyId: user?.companyId as string,
            contactId: applicationData.contactId,
            userId: user?.id as string,
          });
        },
      }
    );

  return (
    <>
      <div className="form-container">
        <Space direction="vertical">
          <Text strong>Intake</Text>
          <DatePicker
            style={{ width: '100%' }}
            format="MMMM YYYY"
            defaultValue={intakeDate as moment.Moment}
            picker="month"
            onChange={handleIntakeUpdate}
            allowClear={false}
          />
        </Space>

        <Space direction="vertical">
          <Text strong>Enrollment period</Text>
          <DatePicker.RangePicker
            data-testid="application-enrollment"
            format="DD/MM/YYYY"
            picker="date"
            onChange={handleEnrollmentUpdate}
            defaultValue={[
              enrollmentStartDate as Moment,
              enrollmentEndDate as Moment,
            ]}
            allowClear={false}
          />
        </Space>

        <InputButtonWrapper
          onSubmit={() => updatePartnerClientId(input.partnerClientId)}
          onCancel={() =>
            setInput((input) => ({
              ...input,
              partnerClientId: initialInput.partnerClientId,
            }))
          }
        >
          <Space direction="vertical">
            <Text strong>Provider Student ID</Text>
            <Input
              onChange={(e) =>
                setInput((input) => ({
                  ...input,
                  partnerClientId: e.target.value,
                }))
              }
              value={input.partnerClientId}
            />
          </Space>
        </InputButtonWrapper>

        <Space direction="vertical">
          <Text strong>Application Date</Text>
          <DatePicker
            format="DD MMMM YYYY"
            defaultValue={parseDate(
              workflowMetadata?.applicationDate,
              'DD/MM/YYYY'
            )}
            picker="date"
            onChange={(data) => {
              updateApplicationMutation.mutate(
                {
                  workflowMetadata: {
                    ...workflowMetadata,
                    applicationDate: data?.format('DD/MM/YYYY'),
                  },
                },
                {
                  onSuccess: (data) => {
                    successNotificationHandler(
                      'Application date updated successfully.'
                    );
                  },
                }
              );
            }}
            allowClear={false}
          />
        </Space>
      </div>
    </>
  );
};
