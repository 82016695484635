import React, { useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { Button, getFileName, Input } from '@moxie/shared';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { IDocNameForm } from '@shared-components/models';
import {
    FILE_NAME_MAXIMUM,
    FILE_NAME_REQUIRED,
    INVALID_FILE_NAME,
    regex,
} from '@moxie/constants';
import { FormInstance } from 'antd/es/form';
import { Media } from '@model/document';

 interface Props {
    handleSubmit: (data: IDocNameForm) => void;
    handleCancel: () => void;
    form: FormInstance<IDocNameForm> | any;
    documentData: Media;
  }
const DocNameEdit: React.FC<Props> = ({
    handleSubmit,
    handleCancel,
    form,
    documentData,
}) => {
    useEffect(() => {
        form.setFieldsValue({
            fileName:
                documentData.name ||
                (documentData?.id ? documentData.name : getFileName(documentData.name)),
        });
    }, [form, documentData]);

    return (
        <Form layout="inline" form={form} onFinish={handleSubmit}>
            <Row justify="space-between">
                <Col span={18}>
                    <Form.Item
                        name="fileName"
                        className="document-name show_text_overflow"
                        rules={[
                            { required: true, message: FILE_NAME_REQUIRED },
                            { max: 50, message: FILE_NAME_MAXIMUM },
                            {
                                pattern: new RegExp(regex.FILE_NAME),
                                message: INVALID_FILE_NAME,
                            },
                        ]}
                    >
                        <Input className="document-name-edit-card-input" />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item>
                        <Button
                            size="small"
                            type="text"
                            icon={<CheckOutlined />}
                            onClick={() => form.submit()}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item>
                        <Button
                            size="small"
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={handleCancel}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export { DocNameEdit };
