import React, { useState } from "react";
import Modal from "antd/es/modal";
import { Form, Typography } from "antd";
import Select from "antd/es/select";
import Row from "antd/es/row";
import QRCode from "react-qr-code";
import CopyableInputText from "@crm/src/shared/copyable-input-text/copyable-input-text";
import { srcOptions } from "../../constants";


interface ShareUrlModalProps {
  showShareModal: boolean;
  setShowShareModal: React.Dispatch<React.SetStateAction<boolean>>;
}


const ShareUrlModal = ({ showShareModal, setShowShareModal }: ShareUrlModalProps) => {
  const [source, setSource] = useState(srcOptions[0]);
  const url = `${window.location.origin}/enquiry-form?source=${source}`;

  return (
    <Modal visible={showShareModal}
      title={<Typography.Text style={{ fontWeight: '700' }}>Sharing Options</Typography.Text>}
      onCancel={() => setShowShareModal(false)}
      footer={null}
    >
      <Form layout="vertical" className="padding-1">
        <Form.Item label="Source">
          <Select value={source} showArrow onChange={setSource}>
            {srcOptions.map((src, index) =>
              (<Select.Option key={index} value={src}>{src}</Select.Option>)
            )}
          </Select>
        </Form.Item>
        <Row align="middle" justify="center" className="margin-bottom-2">
          <QRCode value={url} size={150} />
        </Row>
        <Form.Item label="Shareable Link">
          <CopyableInputText key={`text-${source}`} value={url} />
        </Form.Item>
        <Form.Item label="Embed Code">
          <CopyableInputText key={`iframe-${source}`} value={`<iframe src="${url}" title="${url}"></iframe>`} />
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default ShareUrlModal;
