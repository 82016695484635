import React from 'react';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useAssigneeUpdate } from '@crm/libs/hooks/application/useAssigneeUpdate';
import {
  APPLICATION_RESPONSE_MESSAGE,
  NO_ASSIGNEES,
  TEXT,
  regex,
} from '@moxie/constants';
import {
  capitalizeAllLetter,
  capitalizeFirstLetter,
  successNotificationHandler,
} from '@moxie/shared';
import { Button, Col, Form, Row, Tag, Tooltip, Typography, Popover } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { UserAvatarGroup } from 'apps/crm/src/shared/user-avatar-group';
import { useEffect, useState } from 'react';
import { useApplicationFeeMutation } from '../hooks/use-contact-application-fees';
import { EditAgentPartnerComponent } from './agent-partners/edit-agent-partner';
import { ApplicationDetail } from '@model/application';
import UserSearchBox from 'apps/crm/src/shared/user-select-box/user-search-box';
import { useParams } from 'react-router-dom';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { UpdateApplicationDiscountFormField } from '@model/data.model';
import { useAppSelector } from '@crm/core';

//TODO: delete these components if not used in future
import EditFeeComponent from './edit-fee';
import UpdateDiscount from './update-discount';

const { Title, Text, Paragraph } = Typography;
interface Props {
  application: ApplicationDetail;
}
const ProductDetail: React.FC<Props> = ({ application }) => {
  const [form] = useForm();
  const { contactId } = useParams<{ contactId: string }>();
  const socket = useAppSelector(state => state.socket.wss);
  const user = useAppSelector(state => state.auth.user);

  const [editField, setEditField] = useState({
    fees: false,
    discount: false
  });
  const [editAssignees, setEditAssignees] = useState<boolean>(false);
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);

  const assigneeUpdateMutation = useAssigneeUpdate(application.contactId, application?.dealId);
  const feeMutation = useApplicationFeeMutation(application);
  const updateApplicationMutation = useUpdateApplicationMutation(application.id, application.dealId);

  const institution_info = `${application?.institution.name} - ${application?.institutionBranch.name}`;
  const workflow_info = `${application?.workflow?.workflowType?.service?.name} ${application?.workflow?.workflowType?.name} • (${application?.workflow?.country})`;
  const fees = application?.productFeeAmount;
  const currency = application?.institution?.currency;
  const assignees = application?.assignees
    ? application?.assignees?.map((assignee) => assignee?.id)
    : [];

  const ellipsisText = (str: string, maxTextLength: number) =>
    str?.length >= maxTextLength
      ? { tooltip: capitalizeFirstLetter(str) }
      : false;

  const enableAssigneesEdit = () => {
    setEditAssignees(true);
  };

  const handleCloseAssigneesForm = () => {
    setEditAssignees(false);
    form.resetFields();
  };


  const handleAssigneesSubmit = async (data: { assignees: string[] }) => {
    const prevValue = [...assignees];
    const addedAssignees = data.assignees.filter((id: string) => !prevValue.includes(id));
    const removedAssignees = prevValue.filter((id: string) => !data.assignees.includes(id));
    const remainingAssignees = data.assignees.filter((id: string) => prevValue.includes(id));

    await assigneeUpdateMutation.mutateAsync(data.assignees);
    socket?.emit('server::application-assignee-updated', {
      addedAssignees,
      application: application as ApplicationDetail,
      companyId: user?.companyId as string,
      removedAssignees,
      remainingAssignees,
      contactId: application?.contactId as string,
      userId: user?.id as string,
    });

    handleCloseAssigneesForm();
  };

  const handleAssigneeChange = (data: { assignees: string[] }) => {
    if (data?.assignees?.length === 0) {
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  };

  const handleOpenFeeEditField = () => {
    setEditField({
      ...editField,
      fees: true
    })
  };

  const handleUpdateFee = async (value: number) => {
    feeMutation.mutate(Number(value || fees), {
      onSuccess() {
        setEditField({
          ...editField,
          fees: false
        })
        successNotificationHandler('Fee updated successfully.', 1.5);
        socket?.emit('server::application-updated', {
          activitiesAction: 'updated',
          activitiesType: 'application-fee',
          data: {
            data: application
          },
          activitiesTypeId: application.id,
          userId: user?.id as string,
          companyId: user?.companyId as string,
          contactId: contactId,
        })
      },
    }
    );
  };

  const onDiscountSubmit = async (payload: UpdateApplicationDiscountFormField) => {
    await updateApplicationMutation.mutateAsync(payload,
      {
        onSuccess() {
          socket?.emit('server::application-updated', {
            activitiesAction: 'updated',
            activitiesType: 'application-discount',
            data: {
              data: application
            },
            activitiesTypeId: application.id,
            userId: user?.id,
            companyId: user?.companyId as string,
            contactId: contactId,
          })
        },
      }
    );
    setEditField({
      ...editField,
      discount: false
    })
    successNotificationHandler('Discount updated successfully.', 1.5);
    socket?.emit('server::application-updated', {
      activitiesAction: 'updated',
      activitiesType: 'application-discount',
      data: {
        data: application
      },
      activitiesTypeId: application.id,
      userId: user?.id as string,
      companyId: user?.companyId as string,
      contactId: contactId,
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      assignees: assignees,
    });
  }, [application]);

  return (
    <Row className="product-detail-container">
      <Col className="product-col-border" xs={12} xl={10} >
        <Row align="middle" className="product-info-container">
          <div className="w-100">
            <Title
              level={4}
              className="text-primary margin-bottom-0 font-size-1-rem ellipsis-text"
              ellipsis={ellipsisText(application?.product.name, 16)}
            >
              {capitalizeFirstLetter(String(application?.product.name))}
            </Title>
          </div>
          <div className="w-100">
            <Text
              type="secondary"
              className="margin-bottom-0 margin-top-0 font-size-point-9-rem ellipsis-text"
              ellipsis={ellipsisText(institution_info, 16)}
            >
              {capitalizeFirstLetter(institution_info)}
            </Text>
          </div>
        </Row>
        <Row align="middle" className="product-wf-info">
          <Title
            className="text-primary margin-bottom-0 font-size-point-9-rem "
            level={5}
          >
            {workflow_info}
          </Title>
        </Row>
      </Col>
      <Col className="product-col-border" xs={12} xl={7}>
        <div className="margin-left-2 margin-top-1">
          <Text className="administrative-info-title">
            {TEXT.PROCESSING_BRANCH}:
          </Text>
          <Paragraph
            ellipsis={{
              rows: 1,
              expandable: false,
              tooltip: true,
            }}
            className="initial_capital"
          >
            <Tag>{application?.processingBranch?.name}</Tag>
          </Paragraph>
          <Text className="administrative-info-title">{TEXT.ASSIGNEES}:</Text>
          <div data-testid="applicationassignee" className="cursor-pointer">
            {application?.assignees?.length > 0 && !editAssignees ? (
              <div className="profile__user" onClick={enableAssigneesEdit}>
                <UserAvatarGroup userList={application?.assignees} />
              </div>
            ) : (
              !editAssignees && (
                <div>
                  <Typography.Text
                    type="secondary"
                    className="font-weight-500 margin-top-minus-5-px"
                  >
                    {NO_ASSIGNEES}
                  </Typography.Text>
                </div>
              )
            )}
            {editAssignees && (
              <Form
                form={form}
                onValuesChange={handleAssigneeChange}
                onFinish={handleAssigneesSubmit}
                className="padding-left-1"
                initialValues={{
                  assignees: assignees,
                }}
              >
                <Row>
                  <Col span={18}>
                    <Form.Item
                      className="application-follower_overflow"
                      name="assignees"
                    >
                      <UserSearchBox filter={{ contactId }} mode="multiple" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Tooltip
                      title={
                        submitDisable
                          ? APPLICATION_RESPONSE_MESSAGE.ASSIGNEE_REQUIRED
                          : ''
                      }
                    >
                      <Button
                        icon={
                          <CheckOutlined data-testid="applicationassigneecheck" />
                        }
                        type="link"
                        htmlType="submit"
                        size="small"
                        disabled={submitDisable}
                      />
                    </Tooltip>
                    <Button
                      icon={<CloseOutlined />}
                      onClick={handleCloseAssigneesForm}
                      type="link"
                      danger
                      size="small"
                      className="padding-left-1"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        </div>
      </Col>
      <Col className="product-col-border" xs={12} xl={7}>
        <div className="margin-left-2 margin-top-1">
          <EditAgentPartnerComponent application={application} />
        </div>
      </Col>
      {/* <Col className="product-col-border" xs={12} xl={5}>
        <div className="margin-left-2">
          {' '}
          <Row className=" margin-top-1">
            <Col span={18}>
              <Text className="administrative-info-title">{TEXT.FEE}:</Text>
            </Col>
            {editField.fees ? null : <Col span={6}>
              <EditOutlined
                data-testid="applicationfee"
                className="margin-left-1"
                onClick={handleOpenFeeEditField}
              />
            </Col>}
          </Row>
          <Row>
            <Row className="full-width">
              {!editField.fees ? (
                <Text className="administrative-info-title">
                  {fees?.toString().replace(new RegExp(regex.FEES), ',')}{' '}
                  {capitalizeAllLetter(currency)}
                </Text>
              ) : (
                editField.fees && (
                  <EditFeeComponent
                    amount={fees}
                    currency={currency}
                    onClose={() => setEditField({
                      ...editField,
                      fees: false
                    })}
                    onSubmit={handleUpdateFee}
                  />
                )
              )}
            </Row>
          </Row >
        </div >
        <div className="margin-left-2">
          <Row className="margin-top-1">
            <Col span={18}>
              <Text className="administrative-info-title">{TEXT.DISCOUNT}:</Text>
            </Col>
            <Col span={6}>
              <Popover
                trigger="click"
                placement="bottom"
                defaultVisible={false}
                visible={editField.discount}
                content={
                  <UpdateDiscount
                    amount={application.discount}
                    remarks={application.remarks}
                    currency={currency}
                    onClose={() => setEditField({
                      ...editField,
                      discount: false
                    })}
                    onSubmit={onDiscountSubmit}
                  />
                }
              >
                <EditOutlined
                  data-testid="applicationfee"
                  className="margin-left-1"
                  onClick={() => setEditField({
                    ...editField,
                    discount: true
                  })}
                />

              </Popover>
            </Col >
          </Row >
          <Row align="middle" className="application-fee">
            <Col>
              <Text className="administrative-info-title">
                {application.discount?.toString().replace(new RegExp(regex.FEES), ',')}{' '}
                {capitalizeAllLetter(currency)}
              </Text>
            </Col>
            {application.remarks?.length ? (
              <Col>
                <Text className="initial_capital truncate display-block" ellipsis={application.remarks ? ellipsisText(application.remarks, 14) : undefined}>
                  •&nbsp;&nbsp;{application.remarks}
                </Text>
              </Col>
            ) : null}
          </Row >
        </div >
      </Col> */}
    </Row >
  );
};

export default ProductDetail;
