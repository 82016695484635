import React from 'react';
import { ContactActivity } from '@model/contact-activity';
import { Link } from 'react-router-dom';
import { UNASSIGNED_LABEL } from '@moxie/constants';

interface Props
  extends Pick<
    ContactActivity,
    | 'activitiesAction'
    | 'activitiesType'
    | 'data'
    | 'assignedUser'
    | 'previousAssignedUser'
    | 'follower'
    | 'activitiesTypeId'
    | 'contactId'
  > {
  showLink: boolean;
}
const ActivityMessage: React.FC<Props> = ({
  activitiesAction: activities_action,
  activitiesType: activities_type,
  activitiesTypeId: activities_type_id,
  data,
  previousAssignedUser: previous_assigned_user,
  assignedUser: assigned_user,
  follower,
  showLink,
  contactId,
}) => {

  let previouslyAssignedUser;
  let assignedUser;

  if (!previous_assigned_user) {
    previouslyAssignedUser = UNASSIGNED_LABEL;
  } else {
    previouslyAssignedUser = `${previous_assigned_user?.firstName} ${previous_assigned_user?.lastName}`;
  }

  if (!assigned_user) {
    assignedUser = UNASSIGNED_LABEL;
  } else {
    assignedUser = `${assigned_user?.firstName} ${assigned_user?.lastName}`;
  }

  const followerLabel = `${follower?.firstName ?? ''} ${follower?.lastName ?? ''
    }`;
  const companyCode = `${data?.data?.company?.company_code ? data?.data?.company?.company_code + '-' : ''}${data?.data?.appIdentifier}`;

  const applicationLink = React.useMemo(() => {
    if (showLink) {
      return (
        <Link
          className="initial_capital"
          to={`/application/detail/${data?.data?.contactId}/${data?.data?.id}`}
        >
          for the application {companyCode}
        </Link>
      );
    }

    return showLink;
  }, [companyCode, data, showLink]);

  const dealLink = React.useMemo(() => {
    if (showLink) {
      return (
        <span> The Deal
          < Link
            className="initial_capital"
            to={`/contact/${contactId}/deals/${activities_type_id}`
            }
          >
            <span>{' '}</span>
            {`${data?.dealInternalId}`}
          </Link >
        </span>
      );
    }

    return showLink;
  }, [companyCode, data, showLink]);

  switch (activities_action) {
    case 'created':
      switch (activities_type) {
        case 'language-test':
          return (
            <span>
              Added the{' '}
              <span className="contact-activities-stage">
                english test score for{' '}
                {data?.testName?.toUpperCase()}
              </span >
            </span >
          );
        case 'emergency-contact':
          return (
            <span>
              Created{' '}
              <span className="contact-activities-stage">
                Emergency contact details
              </span>
            </span>
          );

        case 'professional-experience':
          return (
            <span>
              Created{' '}
              <span className="contact-activities-stage">
                Professional experience
              </span>
            </span>
          );
        case 'education':
          return (
            <span>
              Created{' '}
              <span className="contact-activities-stage">
                education background
              </span>
            </span>
          );

        case 'document':
          return (
            <span>
              Document<span className="initial_small">(s)</span> uploaded in{' '}
              <span className="contact-activities-stage">
                {data?.subCategory}
              </span>{' '}
            </span>
          );

        case 'application':
          return (
            <span>
              Created the application {applicationLink}
              {''}
            </span>
          );
        case 'follower':
          return (
            <span>
              <span className="contact-activities-stage">{followerLabel}</span>{' '}
              added as followers
            </span>
          );

        case 'application-document':
          return (
            <span>
              Uploaded document<span className="initial_small">(s)</span> for{' '}
              <span className="contact-activities-stage">
                {data?.data?.documentType}
              </span > {' '}
              in {' '}
              < span className="contact-activities-stage" >
                {data?.data?.currentStage?.stage}
              </span >
            </span >
          );

        case 'application-comment':
          return (
            <span>Added Comments in the <span className="contact-activities-stage">{data?.data?.currentStage?.stage}</span></span>
          )

        case 'branch':
          return (
            <span>
              Added branch{' '}
              <span className="contact-activities-stage">
                {data?.data?.name}
              </span>
            </span>
          );

        case 'deal':
          return (
            <span>
              Added {dealLink}
            </span>
          );

        default:
          return <span>Created the application</span>;
      }

    case 'deleted':
      switch (activities_type) {
        case 'language-test':
          return (
            <span>
              Deleted the{' '}
              <span className="contact-activities-stage">
                english test score for {data?.testName?.toUpperCase()}
              </span >
            </span >
          );
        case 'emergency-contact':
          return (
            <span>
              Deleted the{' '}
              <span className="contact-activities-stage">
                Emergency contact details
              </span>
            </span>
          );

        case 'professional-experience':
          return (
            <span>
              Deleted the{' '}
              <span className="contact-activities-stage">
                professional experience
              </span>
            </span>
          );
        case 'education':
          return (
            <span>
              Deleted{' '}
              <span className="contact-activities-stage">
                education background
              </span>
            </span>
          );

        case 'document':
          return (
            <span>
              Document deleted in{' '}
              <span className="contact-activities-stage">
                {data?.subCategory}
              </span>{' '}
            </span>
          );

        case 'follower':
          return (
            <span>
              <span className="contact-activities-stage">{followerLabel}</span>{' '}
              removed as followers
            </span>
          );

        case 'application-document':
          return (
            <span>
              Deleted document from{' '}
              <span className="contact-activities-stage">
                {data?.data?.documentType}
              </span > {' '}
              in {' '}
              < span className="contact-activities-stage" >
                {data?.data?.currentStage?.stage}
              </span >
            </span >
          );

        case 'branch':
          return (
            <span>
              Removed branch{' '}
              <span className="contact-activities-stage">
                {data?.data?.name}
              </span>
            </span>
          );

        default:
          return <span>Deleted the application</span>;
      }

    case 'updated':
      switch (activities_type) {

        case 'application-partner-client-id':
          return <span>Updated <span className="contact-activities-stage">application partner client ID</span> {applicationLink}</span>;

        case 'language-test':
          return (
            <span>
              Updated the{' '}
              <span className="contact-activities-stage">
                english test score for{' '}
                {data?.testName?.toUpperCase()}
              </span >
            </span >
          );

        case 'address-information':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">
                address information
              </span>
            </span>
          );

        case 'personal-information':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">
                personal information
              </span>
            </span>
          );

        case 'passport-and-visa':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">
                passport and visa information
              </span>
            </span>
          );

        case 'emergency-contact':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">
                emergency contact details
              </span>
            </span>
          );

        case 'assignee':
          return <span>Updated Assignee {applicationLink}</span>;

        case 'intake':
          return <span>Updated Intake {applicationLink}</span>;

        case 'enrollment-period':
          return <span>Updated Enrollment Period {applicationLink}</span>;

        case 'discontinued':
          return (
            <span>
              Discontinued the application
              {applicationLink}
            </span>
          );

        case 'professional-experience':
          return (
            <span>
              Updated the{' '}
              <span className="contact-activities-stage">
                Professional experience
              </span>
            </span>
          );

        case 'education':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">
                education background
              </span>
            </span>
          );

        case 'document':
          return (
            <span>
              Document updated in{' '}
              <span className="contact-activities-stage">
                {data?.subCategory}
              </span>{' '}
            </span>
          );

        case 'application-intake':
          return (
            <span>
              Updated <span className="contact-activities-stage">Intake</span>{' '}
              {applicationLink}
            </span>
          );

        case 'application-enrollment':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">
                enrollment period
              </span>{' '}
              {applicationLink}
            </span>
          );

        case 'application-fee':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">application fees</span>
              {' '}for the application {applicationLink}
            </span>
          );

        case 'application-discount':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">
                application discount
              </span>
              {' '}for the application {applicationLink}
            </span>
          );

        case 'application-sub-agent':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">Sub agent</span>
              {' '}for the application {applicationLink}
            </span>
          );

        case 'application-super-agent':
          return (
            <span>
              Updated{' '}
              <span className="contact-activities-stage">Super agent</span>
              {' '}for the application {applicationLink}
            </span>
          );

        case 'application-document':
          return (
            <span>
              Updated document<span className="initial_small">(s)</span> for{' '}
              <span className="contact-activities-stage">
                {data?.data?.documentType}
              </span>{' '}
              in{' '}
              <span className="contact-activities-stage">
                {data?.data?.currentStage?.stage}
              </span >
            </span >
          );

        case 'archive':
          return (
            <span>
              <span className="contact-activities-stage">
                {data?.archived ? 'Archived' : 'Restored'}</span>
              {' '} the lead
            </span>
          );

        case 'basic-detail':
          return (
            <span>
              Updated the{' '}
              <span className="contact-activities-stage">
                basic detail
              </span >
            </span >
          );


        case 'deal':
          return (
            <span>
              Updated {dealLink}
            </span >
          );

        default:
          return <span>Updated the application</span>;
      }

    case 'discontinued':
      return (
        <span>
          Discontinued the application {applicationLink}
          {''}
        </span>
      );

    case 'next-stage':
      return (
        <>
          Progressed the application {applicationLink} from{' '}
          <span className="contact-activities-stage">
            {data?.data?.previousStage?.stage}
          </span>{' '}
          to{' '}
          <span className="contact-activities-stage">
            {data?.data?.currentStage?.stage}
          </span>
        </>
      );

    case 'revert':
      return (
        <span>
          Reverted the application {applicationLink}
          {''}
        </span>
      );

    case 'previous-stage':
      return (
        <>
          Regressed the application {applicationLink} from{' '}
          <span className="contact-activities-stage">
            {data?.data?.previousStage?.stage}
          </span>{' '}
          to{' '}
          <span className="contact-activities-stage">
            {data?.data?.currentStage?.stage}
          </span>
        </>
      );

    case 'completed':
      return (
        <div>
          Progressed the application {applicationLink} from{' '}
          <span className="contact-activities-stage">
            {data?.data?.previousStage?.stage}
          </span > {' '}
          to <span className="contact-activities-stage" > Complete</span >
        </div >
      );

    case 'assigned':
      switch (activities_type) {
        case 'contact':
          return (
            <span>
              Changed assignee from{' '}
              <span className="contact-activities-stage">
                {previouslyAssignedUser}
              </span>{' '}
              to{' '}
              <span className="contact-activities-stage">{assignedUser}</span>{' '}
            </span>
          );

        default:
          return <span>Assigned added to application</span>;
      }

    default:
      return <span>Application Updated</span>;
  }
};

export default ActivityMessage;
