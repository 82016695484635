import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const InputButtonWrapper = ({
  children,
  onSubmit,
  onCancel,
}: {
  children: ReactNode;
  onSubmit: () => any;
  onCancel?: () => any;
}) => {
  const ref = useRef<HTMLDivElement>(null!);
  const [isFocussed, setIsFocussed] = useState(false);

  const handleSuccess = useCallback(() => {
    onSubmit();
    setIsFocussed(false);
  }, [onSubmit]);

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
    setIsFocussed(false);
  }, [onCancel]);

  useEffect(() => {
    const wrapper = ref.current;
    const inputElement = wrapper.querySelector('input');

    const handleFocus = (e: FocusEvent) => {
      setIsFocussed(true);
    };
    const handleBlur = (e: FocusEvent) => {
      setIsFocussed(false);
    };
    const handlePress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSuccess();
        // inputElement?.blur();
      }
      if (e.key === 'Escape') {
        handleCancel();
        inputElement?.blur();
      }
    };

    // inputElement?.addEventListener('focusout', handleBlur);
    wrapper?.addEventListener('focusin', handleFocus);
    wrapper?.addEventListener('keydown', handlePress);

    return () => {
      wrapper?.removeEventListener('focusin', handleFocus);
      // inputElement?.removeEventListener('focusout', handleBlur);
      wrapper?.removeEventListener('keypress', handlePress);
    };
  }, [handleSuccess, handleCancel]);

  return (
    <div ref={ref} id="input-button-wrapper">
      {isFocussed && (
        <div className="button-container">
          <button onClick={handleSuccess}>
            <CheckOutlined />
          </button>
          <button onClick={handleCancel}>
            <CloseOutlined />
          </button>
        </div>
      )}
      {children}
    </div>
  );
};
