import { queryAssignees } from '@crm/services.api';
import { InputButtonWrapper } from '@crm/src/shared/input-button-wrapper';
import { UserAvatarGroup } from '@crm/src/shared/user-avatar-group';
import UnassignedCard from '@crm/src/shared/user-card/unassigned-card';
import { Assignee, UpdateApplicationPayload } from '@model/application';
import { IGetPopupContainer } from '@model/data.model';
import { COUNTRIES } from '@moxie/constants';
import { successNotificationHandler } from '@moxie/shared';
import {
  Option, SearchableSelect, UserNameCard
} from '@shared-components/elements';
import { DatePicker, Input, Select, Space, Typography } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import { Moment } from 'moment';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { parseDate } from './application-details-tab';

const { Text } = Typography;

const formInfo = [
  {
    service: 'Visa',
    fields: [
      {
        field: 'visaTRN',
        label: 'Visa TRN',
        type: 'text',
      },
      {
        field: 'visaLodgementDate',
        label: 'Visa lodgment date',
        type: 'date',
      },
      {
        field: 'visaOutcomeDate',
        label: 'Visa outcome date',
        type: 'date',
        // format:""
      },
      {
        field: 'bridgingVisaType',
        label: 'Briding visa type',
        type: 'number',
      },
      {
        field: 'countryOfPoliceCheck',
        label: 'Country of police check',
        type: 'select-country',
      },
      {
        field: 'policeCheckApplicationDate',
        label: 'Police check application date',
        type: 'date',
      },
      {
        field: 'policeCheckExpiryDate',
        label: 'Police check expiry date',
        type: 'date',
      },
      {
        field: 'HAPID',
        label: 'HAP ID',
        type: 'number',
      },
      {
        field: 'visaMedicalDate',
        label: 'Visa medical date',
        type: 'date',
      },
      {
        field: 'visaMedicalCountry',
        label: 'Visa medical country',
        type: 'select-country',
      },
      {
        field: 'RMA',
        label: 'RMA',
        type: 'searchbox-select',
      },
      {
        field: 'assessmentOccupation',
        label: 'Assessment occupation',
        type: 'text',
      },
      {
        field: 'skillsAssessmentBody',
        label: 'Skills assessment body',
        type: 'text',
      },
      {
        field: 'skillsAssessmentExpiry',
        label: 'Skills assessment expiry date',
        type: 'date',
      },
      {
        field: 'englishTestDate',
        label: 'English test date',
        type: 'date',
      },
      {
        field: 'englishTestExpiry',
        label: 'English test expiry date',
        type: 'date',
      },
    ],
  },
];

export const VisaForm = () => {
  const { applicationData } = useContext(ApplicationDetailContext);
  const { id: applicationId, workflowMetadata } = applicationData;
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationId,
    applicationData?.dealId
  );
  const initialInput = {
    visaTRN: workflowMetadata?.visaTRN ?? '',
    bridgingVisaType: workflowMetadata?.bridgingVisaType ?? '',
    HAPID: workflowMetadata?.HAPID ?? '',
    assessmentOccupation: workflowMetadata?.assessmentOccupation ?? '',
    skillsAssessmentBody: workflowMetadata?.skillsAssessmentBody ?? '',
  };
  const [input, setInput] = useState(initialInput);
  const [isRmaSelected, setIsRmaSelected] = useState(false);
  const [currentRma, setCurrentRma] = useState<Assignee[]>([]);

  useEffect(() => {
    if (workflowMetadata?.RMA?.length) {
      queryAssignees({
        'filter.id': `$in:${workflowMetadata.RMA.join(',')}`,
      }).then((data) => setCurrentRma(data.data.data));
    }
  }, [workflowMetadata?.RMA?.length]);

  function updateData(payload: UpdateApplicationPayload, field: string) {
    updateApplicationMutation.mutate(payload, {
      onSuccess: () => {
        successNotificationHandler(`${field} updated successfully`, 1.5);
      },
    });
  }

  return (
    <div className="form-container">
      {formInfo.map((form) =>
        form.fields.map((field) => {
          if (field.type === 'text' || field.type === 'number') {
            const payload = {
              workflowMetadata: {
                ...workflowMetadata,
                [field.field]: input[field.field as keyof typeof input],
              },
            };
            const onCancel = () =>
              setInput((input) => ({
                ...input,
                [field.field]: initialInput[field.field as keyof typeof input],
              }));
            const onChange = (e: ChangeEvent<HTMLInputElement>) =>
              setInput((input) => ({
                ...input,
                [field.field]: e.target.value,
              }));

            return (
              <InputButtonWrapper
                onSubmit={() => updateData(payload, field.label)}
                onCancel={onCancel}
              >
                <Space direction="vertical">
                  <Text strong style={{ textTransform: 'capitalize' }}>
                    {field.label}
                  </Text>
                  <Input
                    type={field.type}
                    onChange={onChange}
                    value={input[field.field as keyof typeof input]}
                  />
                </Space>
              </InputButtonWrapper>
            );
          }

          if (field.type === 'date') {
            const getPayload = (data: Moment | null) => {
              return {
                workflowMetadata: {
                  ...workflowMetadata,
                  [field.field]: data?.format('DD/MM/YYYY') as string,
                },
              };
            };

            return (
              <Space direction="vertical">
                <Text strong style={{ textTransform: 'capitalize' }}>
                  {field.label}
                </Text>
                <DatePicker
                  format="DD MMMM YYYY"
                  defaultValue={parseDate(
                    workflowMetadata?.[field.field as keyof typeof input],
                    'DD/MM/YYYY'
                  )}
                  picker="date"
                  onChange={(data) => {
                    updateData(getPayload(data), field.label);
                  }}
                  allowClear={false}
                />
              </Space>
            );
          }

          if (field.type === 'select-country') {
            const elementId = field.field + '-list';
            const defaultValue = workflowMetadata?.[field.field as keyof typeof input];
            const getPayload = (data: SelectValue) => {
              return {
                workflowMetadata: {
                  ...workflowMetadata,
                  [field.field]: data as string,
                },
              };
            };

            return (
              <Space direction="vertical" id={elementId}>
                <Text strong style={{ textTransform: 'capitalize' }}>
                  {field.label}
                </Text>
                <SearchableSelect
                  optionFilter="search_prop"
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById(elementId)
                  }
                  defaultValue={defaultValue}
                  style={{ width: '100%' }}
                  onChange={(data) => {
                    updateData(getPayload(data), field.label);
                  }}
                >
                  {COUNTRIES.map((item) => {
                    return (
                      <Option
                        value={item.name}
                        key={item.code}
                        search_prop={`${item.name} ${item.code}`}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </Space>
            );
          }

          if (field.type === 'searchbox-select') {
            return (
              <Space direction="vertical" id="visa-country-list">
                <Text strong>RMA</Text>
                {isRmaSelected ? (
                  <SearchBox
                    style={{ width: '100%' }}
                    defaultOpen={false}
                    fetchQuery={queryAssignees}
                    className="assignee-select"
                    mode={'tags'}
                    maxTagCount={1}
                    valueKey="id"
                    autoFocus
                    optionLabelProp="label"
                    optionFilterProp="label"
                    defaultValue={workflowMetadata?.RMA}
                    hideDefaultOptionView
                    onChange={(value, ...options) => {
                      updateData(
                        {
                          workflowMetadata: {
                            ...workflowMetadata,
                            RMA: value,
                          },
                        },
                        'RMA'
                      );
                    }}
                    onBlur={() => setIsRmaSelected(false)}
                  >
                    {(assignee) => {
                      return (
                        <Select.Option
                          key={assignee.id}
                          value={assignee.id}
                          label={`${assignee.firstName} ${assignee.lastName}`}
                        >
                          <UserNameCard
                            firstName={assignee.firstName}
                            lastName={assignee.lastName}
                            email={assignee.email}
                          />
                        </Select.Option>
                      );
                    }}
                  </SearchBox>
                ) : (
                  <div onClick={() => setIsRmaSelected(true)}>
                    {currentRma.length ? (
                      <UserAvatarGroup userList={currentRma} />
                    ) : (
                      <UnassignedCard className="padding-left-2" />
                    )}
                  </div>
                )}
              </Space>
            );
          }

          return null;
        })
      )}
    </div>
  );
};
