import React from 'react';
import { COMMENTS_PLACEHOLDER, LABEL, TEXT, regex } from '@moxie/constants';
import { Editor } from '@shared-components/forms';
import {
  Avatar,
  Col,
  Divider,
  Empty,
  Form,
  List,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetContactApplicationComments } from '../hooks/use-contact-application-comments';

const Comments = ({ form, setDisabled, onSubmit, applicationId }: any) => {
  const handleValueChange = ({ comment }: any) => {
    if (comment?.replace(new RegExp(regex.COMMENTS), '').trim().length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const { data, isLoading, hasMore, next } = useGetContactApplicationComments(
    applicationId
  );
  return (
    <>
      <div id="scrollableDivForComments" className="application-comments">
        <Typography.Title level={5}>{TEXT.PREVIOUS_COMMENTS}</Typography.Title>
        <InfiniteScroll
          hasMore={hasMore}
          next={next}
          dataLength={data?.length}
          loader={isLoading ? <Spin /> : null}
          scrollableTarget="scrollableDivForComments"
          endMessage={
            data?.length > 10 ? (
              <Divider plain>{TEXT.NO_MORE_COMMENTS}</Divider>
            ) : null
          }
          style={{ overflowY: 'hidden' }}
        >
          {data?.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={TEXT.NO_DATA}
            />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => {
                const createdUser = item.createdBy
                return (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar>
                          {createdUser?.firstName?.slice(0, 1)}
                          {createdUser?.lastName?.slice(0, 1)}
                        </Avatar>
                      }
                      title={
                        <Row>
                          <Col span={20}>
                            <Space>
                              <Typography.Text strong>
                                {createdUser?.firstName}
                                &nbsp;{createdUser?.lastName}
                              </Typography.Text>
                              <Typography.Text type="secondary">
                                {moment(item?.createdAt).fromNow()}
                              </Typography.Text>
                            </Space>
                          </Col>
                          <Col span={4}>
                            <Tag
                              className="float-right initial_capital"
                              color="purple"
                            >
                              {item.applicationStatus === 'Completed'
                                ? 'Complete'
                                : item?.applicationStage.stage}
                            </Tag>
                          </Col>
                        </Row>
                      }
                      description={
                        <Col span={20}>
                          <div className="custom-description">
                            <div
                              className="text-justify"
                              dangerouslySetInnerHTML={{
                                __html: `${item?.comment
                                  .trim()
                                  .replace(/ /g, '&nbsp;')}`,
                              }}
                            ></div>
                          </div>
                        </Col>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          )}
        </InfiniteScroll>
      </div>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          onValuesChange={handleValueChange}
        >
          <Form.Item
            data-testid="commentbox"
            name="comment"
            label={LABEL.COMMENTS}
            rules={[{ required: true }]}
          >
            <Editor placeholder={COMMENTS_PLACEHOLDER} />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default Comments;
